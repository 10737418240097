@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: lightgrey;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #8cafdd;
  border-radius: 8px;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.common_container {
  width: 85%;
  margin: auto;
}


.ant-checkbox-group {
  display: flex;
  justify-content: space-between;
}

.ant-checkbox-wrapper {
  font-size: 17px !important;
  margin-bottom: 7px;
}

.ant-checkbox .ant-checkbox-inner {
  width: 28px !important;
  height: 28px !important;
  border: 2px solid #5f92d4 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--defaultDarkColor) !important;
  border-color: var(--defaultDarkColor) !important;
}

.ant-checkbox .ant-checkbox-inner:after {
  width: 7.714286px !important;
  height: 14.142857px !important;
}

/* select */
.ant-select-selector {
  border: none !important;
  text-align: left !important;
}

.ant-select-single {
  height: 39px !important;
}



.ant-form-item .ant-form-item-explain-error {
  text-align: left;
}

/* carousel */
.ant-carousel .slick-dots li.slick-active button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
  border: 2px solid var(--defaultDarkColor) !important;
  background-color: var(--defaultDarkColor) !important;
}

.ant-carousel .slick-dots li button {
  width: 10px !important;
  background-color: var(--defaultDarkColor) !important;
  border: 2px solid var(--defaultDarkColor) !important;
  height: 10px !important;
  border-radius: 100% !important;
}

.ant-carousel .slick-dots li.slick-active {
  width: 14px !important;
}

ant-carousel .slick-dots-bottom {
  bottom: -2px !important;
}

/* Loader div */
.loader_div {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ***************Not Found ************** */
.not_found_div {
  height: 100vh;
  display: grid;
  place-items: center;
}

.oops_text {
  font: 800 10rem "Poppins", sans-serif;
  background-image: url("./assets/home_sliderImg.png");
  /* Set the image */
  background-size: cover;
  /* Make sure the image covers the entire text */
  display: inline-block;
  /* Make sure the container fits the text */
  -webkit-background-clip: text;
  /* Clip the background to the text */
  color: transparent;
  /* Hide the actual text */
}

.modalInitial {
  max-width: 620px !important;
  width: 620px !important;
  top: 20px;
}

.overflow-hidden-text {
  white-space: nowrap;       
  overflow: hidden;         
  text-overflow: ellipsis;  
  max-width: 100%;          
}

@media (max-width: 620px) {
  .modalInitial {
    max-width: 500px !important;
    width: 85% !important;
  }
}
:root {
  --defaultDarkColor: #5f92d4;
  --defaultGreyColor: #eff4fb;
  --defaultDark: #5aa6fe;
}

body {
  position: relative;
}

/* antd calendar classes start */
.ant-select-selector {
  min-height: 30px !important;
  border: 1px solid #5aa6fe !important;
}

/* .ant-select-selector .carSelector {
  height: 45px !important;
  border: 1px solid #5aa6fe !important;
} */

.ant-select .ant-select-arrow {
  /* display: none; */
  margin-top: -0.8rem !important;
}

.ant-picker-calendar .ant-picker-cell {
  padding: 0.7rem 0;
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.calendar thead {
  background: #5aa6fe;
  height: 40px;
}

.calendar thead tr th {
  color: #fff !important;
  font-size: 1rem;
}

.calendar .ant-picker-cell-selected .ant-picker-calendar-date {
  background: #5aa6fe !important;
}

.calendar.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}

.calendar {
  height: 100%;
}

.ant-picker-input>input {
  font-weight: bold;
  font-style: oblique;
}

/* antd calendar classes end */

/* *************Utility font classes**************** */
.main_title {
  font-size: 2.2rem;
  font-weight: 800;
  color: #021b46;
}

.title_font {
  font-size: 1.8rem;
  font-weight: 800;
  color: #021b46;
}

.subtitle_font {
  font: 600 1.3rem/2rem "Roboto", sans-serif;
}

.small_font {
  font: 400 1rem/1.4rem "Roboto", sans-serif;
}

/* *************table tag classes**************** */
.td {
  background-color: #d9eafa;
  padding: 5px 10px;
  text-align: end;
}

.td:nth-child(even) {
  background-color: #ecf5fc;
}

/* antd calendar classes start */
.calendar {
  border: 1px solid #b6b6b6;
  margin-top: 0.5rem;
}

/* antd calendar classes end */

/***************** antd classes****************8 */
.ant-modal-mask {
  background: linear-gradient(to right, #20202096, #5f92d4b9);
}

.ant-modal-content {
  border-radius: 20px !important;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-drawer-content-wrapper {
  width: 250px !important;
}

.ant-drawer-content {
  background: #222 !important;
}

.ant-drawer-close {
  color: #fff !important;
}

/***************** Mobile sider classes****************8 */
.mobile_links>a {
  text-decoration: none;
  display: block;
  color: #fff;
  padding: 0.8rem;
  margin-bottom: 1rem;
  font: 400 0.8rem "Roboto", sans-serif;
}

.active_page {
  background: #4a90df;
  font-weight: 600 !important;
  transition: all 0.5s ease-in-out;
}

/***************** verification screen styling****************8 */
.vi__character {
  height: 55px !important;
  line-height: 55px !important;
  border: none !important;
  box-shadow: 0px 5px 15px #0000000d;
  background: #fff !important;
  border: none !important;
  border-radius: 5px;
  background: #eff4fb !important;
  color: #5f92d4;
}

.vi__character--selected {
  outline: 1px solid #5f92d4;
}

.vi__wrapper {
  width: 100% !important;
}

.vi__container {
  gap: 25px !important;
  width: 100% !important;
}

/***************** Header classes****************8 */
.header_background {
  background: #222;
  padding: 1rem;
  width: 100%;
  /* position: fixed;
    z-index: 1; */
}

/********** Flight Details Modal styling**************** */
.dept_arrival {
  background: #ffd602;
  padding: 0.2rem 5rem 0.5rem 1rem;
  border-radius: 0 50px 0 0;
  font: 600 1.1rem "Roboto", sans-serif;
  min-width: 200px;
  width: fit-content;
}

.adjust_pickup_time {
  border: 1px solid #4b90de;
  background: #ffd602;
  text-align: center;
  padding: 0.2rem;
  margin-top: 0.5rem;
}

/********** common styling**************** */
.PhoneInputInput {
  border: none;
  outline: none;
}

.common_modal_width {
  width: 45% !important;
}

.airline_modal_width {
  width: 60% !important;
}

.slider_backImage {
  background-image: url("../../assets/home_sliderImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.slider_backImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.slider_imgButton {
  width: 27rem;
  font-size: 1rem;
  color: rgb(0, 0, 0);
  margin-top: 100px;
  height: 60px;
  background-color: var(--defaultDarkColor);
  border-radius: 40px;
  font-weight: 600;
}

.logo img {
  width: 40%;
}

.all_content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header_btn {
  border-radius: 25px;
  height: 41px;
  color: #fff;
  padding-inline: 25px;
}

.navbar_main_div {
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar {
  gap: 60px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.call_icon_circle {
  background-color: #000;
  height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 100%;
  margin-right: -1.5rem;
}

.text_div {
  margin-top: 100px;
}

.cardBG {
  background-color: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}

.number_div {
  padding-inline: 20px;
  padding-left: 40px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.nav_link {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}
.nav_link:hover {
  font-weight:900 !important;
  text-decoration: underline;
}

.nav_link.active {
  font-weight:bolder;
}

.phne_number {
  font-weight: 600;
  font-size: 1rem;
  color: #5f92d4;
}

.header_txt {
  font-size: 1.1rem;
}

.tab_buttons_div {
  gap: 100px;
}

.tab_button {
  height: 50px;
  border-radius: 20px 20px 0 0;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  transition: all 0.5s;
  /* margin-bottom: 1rem; */
}

.tab_button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #5f92d43e;
  border-radius: 20px 20px 0 0;
  transition: all 0.5s;
}

.tab_button:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}

/* .tab_button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    transform: scale(1.2, 1.2);
  } */
.tab_button:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.active_tab_btn {
  height: 50px;
  width: 100%;
  background-color: #4b90de;
  color: #fff;
  font: 600 1rem "Poppins", sans-serif;
  border-radius: 20px 20px 0 0;
  border-bottom: 2px solid #fff;
}

.rider_main {
  /* background-image: url("../../assets/riderPage_back.png"); */
  background: #5f92d415;
  overflow: auto;
}

.home_formDiv {
  /* min-height: 300px; */
  background-color: var(--defaultGreyColor);
  border-radius: 12px;
  border: 2px solid #4a90df;
  padding: 1.5rem;
}

.affiliate_div {
  height: 100%;
  border: 2px solid #4a90df;
  border-radius: 20px;
}

.transform_btn {
  height: 50px !important;
  border-radius: 17px !important;
}

.input_box {
  background-color: #fff;
  height: 40px;
  box-shadow: 0px 5px 10px #5f92d426 !important;
}

.contact_input {
  background-color: #f9f9f9;
  height: 35px;
  font-size: 0.9rem;
}

.contact_input::placeholder {
  color: #000;
}

.title_txt {
  font-size: 3.5rem;
}

.submit_btn {
  background-color: var(--defaultDark);
  border-radius: 25px;
  height: 40px;
  width: 20%;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0px;
}

.card_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 38vh;
  border-radius: 8px;
}

.card_title {
  font-size: 1.378rem;
  font-weight: 600;
}

.text_width {
  width: 90%;
  margin: auto;
}

.footer_link {
  font-size: 0.9rem;
}

.solidApp_logo {
  height: 30px !important;
}

/* reserve form */
.profile_pic {
  border: 2px dotted var(--defaultDarkColor);
  height: 120px !important;
  width: 124px !important;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 40px; */
}

.reserve_form {
  background-color: var(--defaultDark);
  border-radius: 8px;
  padding: 1rem;
}

.customer_info {
  background: var(--defaultDark);
  border-radius: 10px;
  padding: 0.6rem 0.6rem 0 0.6rem;
}

.time_div {
  /* background: #fff; */
  background: #F6F7C3;
  padding: 0.5rem;
  position: absolute;
  top: 15%;
  left: 2%;
  border: 2px solid var(--defaultDark);
  box-shadow: 1px 1px 4px #4a90df;
  /* bottom: 1.5%;
    left: 50%;
    transform: translate(-50%);
    width: 50%; */
}

.delete_icon_reserve {
  background-color: #fff;
  padding: 10px 11px;
  height: 50px;
  width: 50px;
  border-radius: 50px !important;
  cursor: pointer;
}

.vehicle_typeMain {
  border-radius: 12px;
  padding: 0.3rem;
  cursor: pointer;
  box-shadow: 0px 5px 15px #0e4a961a;
  text-align: center;
  transition: all 0.2s ease;
  /* border: 1px solid #8b8b8b; */
}

.carimage {
  height: 85px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.reservationImg {
  height: 55px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.vehicle_type {
  border-radius: 12px;
  padding: 0.7rem;
  cursor: pointer;
  box-shadow: 0px 5px 15px #0e4a961a;
  text-align: center;
  width: 100%;
  margin: auto;
}

.vehicle_car_image {
  height: 70px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dot_position {
  height: 15px;
  width: 15px;
  background: #5f92d4;
  border-radius: 50%;
}

/* .vehicleType_carParent {
    height: 39px;
  } */

.car_titleDiv {
  min-height: 120px;
  border: 2px solid var(--defaultDarkColor);
  border-radius: 12px;
  background-color: #eff4fb;
}

.car_image_div {
  position: absolute;
  left: 11%;
  top: -25%;
}

.car_image {
  height: 30px;
}

.social_login {
  height: 160px;
}

.phone_voice {
  height: 30px;
}

.video_parentDiv {
  width: 60%;
  margin: auto;
}

.videoDiv {
  text-align: center;
  position: relative;
  margin: auto;
}

.video {
  width: 100% !important;
  border-radius: 9px;
}

.vidButtons {
  position: absolute;
  top: 45%;
  left: 45%;
  cursor: pointer;
}

.form_contentAndForm {
  background: #fff;
  border-radius: 10px;
  padding: 0.8rem;
}

.carDetail_image img {
  height: 70px !important;
}

.specification_card {
  box-shadow: 0px 5px 12px #0000000f;
  border-radius: 10px;
  opacity: 1;
  min-width: 28%;
}

.cardDetailMainDiv {
  padding-inline: 50px;
}

.select_button {
  background-color: var(--defaultDarkColor);
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  padding-inline: 64px;
}

.common_input {
  height: 35px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  border: 2px solid var(--defaultDark);
  box-shadow: 1px 1px 4px #8b8b8b;
}

/* ecf005 */
.detail_input {
  height: 40px;
  border-radius: 50px;
  padding: 0 1rem;
}

.detail_input::placeholder {
  color: #000;
}

.ins_input {
  height: 40px;
  border: 1px solid #5f92d4;
  box-shadow: none;
}

.ins_input::placeholder {
  color: #222;
  font: 600 1rem "Roboto", sans-serif;
}

.gradient_input {
  border-image: linear-gradient(to right, #5aa6fe 40%, #ecf005);
  border-image-slice: 1;
}

.focus_input {
  background: #f6f7c3;
}

.PhoneInputInput {
  background: #f6f7c3;
}

.promo_input {
  height: 50px;
  border: 3px dashed var(--defaultDark);
  border-radius: 50px;
}

.ant-input::placeholder {
  color: var(--defaultDark) !important;
  font: 600 1rem "Roboto", sans-serif;
}

.ant-picker-input>input::placeholder {
  color: var(--defaultDark) !important;
  font: 600 1rem "Roboto", sans-serif;
}

.ant-input-disabled {
  color: #000 !important;
}

/* .autocompleteFieldSec{
      background-color: #fff;
  }
  .autocompleteField{
      background-color: #fff;
  } */

/* 
  .playIcon {
      position: absolute;
      top: 45%;
      left: 40%;
      cursor: pointer;
  }
  
  .pauseIcon {
      position: absolute;
      top: 45%;
      left: 47%;
      cursor: pointer;
  } */

/* media query */
/* @media  (min-width:1500px) and (max-width:1609px) {
      .map_class{
          height: 640px;
      }
  } */

/* Contact part styling */
.contact_margin {
  margin-top: -10rem;
  margin-bottom: 5rem;
  padding: 0 0.7rem;
}

.discount_card {
  background: #eff4fb;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  height: 180px;
  overflow: auto;
}

.discount_card::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1700px) {
  .common_input {
    height: 40px;
  }
}

@media screen and (max-width: 1609px) {
  .navbar_div {
    width: 83%;
  }
}

@media screen and (max-width: 1371px) {
  .navbar_div {
    width: 89%;
    gap: 23px;
  }

  .tab_buttons_div {
    gap: 20px;
  }

  /* .ant-checkbox-group {
      column-gap: 49px !important;
    } */
  .airline_modal_width {
    width: 75% !important;
  }

  /* .time_div {
      width: 60%;
    } */
}

@media screen and (max-width: 1024px) {
  .common_container {
    width: 95%;
  }

  /* .ant-checkbox-group {
      column-gap: 49px !important;
    } */

  .card_image {
    height: 30vh;
  }
}

@media screen and (max-width: 992px) {
  .hide {
    display: none !important;
  }

  .title_txt {
    font-size: 2rem;
  }

  .slider_imgButton {
    width: fit-content !important;
    margin-top: 60px;
    flex-wrap: wrap;
  }

  .airline_modal_width {
    width: 100% !important;
  }

  .logo img {
    width: 60%;
  }

  .loader_div>img {
    width: 20%;
  }

  .contact_margin {
    margin-top: -5rem;
    margin-bottom: 3rem;
  }

  .card_image {
    height: 25vh;
  }
}

@media screen and (max-width: 550px) {
  .reserve_form {
    padding: 0.5rem;
  }

  .title_font {
    font-size: 1.2rem;
  }

  .subtitle_font {
    font: 600 0.9rem/1.8rem "Roboto", sans-serif;
  }

  .vehicle_car_image {
    height: 70px;
  }

  .carimage {
    height: 70px;
  }

  .submit_btn {
    margin-right: 1px !important;
    margin-bottom: 9px;
    width: 50%;
  }

  .logo img {
    width: 100%;
  }

  .common_container {
    width: 100%;
  }

  .card_image {
    height: 18vh;
  }

  .home_formDiv {
    padding: 1rem;
  }

  .loader_div>img {
    width: 50%;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  /* .time_div {
      width: 90%;
    } */

  .time_div {
    top: 12%;
  }

  .oops_text {
    font: 800 5.5rem "Poppins", sans-serif;
  }

  .timing_div dt,
  .timing_div span {
    font-size: 0.8rem;
  }

  .dot_position {
    height: 10px;
    width: 10px;
  }
}

.affiliate-cover {
  margin: 10px auto;
  width: 80%;
}

@media (max-width: 768px) {
  .affiliate-cover {
    width: 100%;
  }
}

@media (max-width: 993px) {
  .marginTop {
    margin-top: 10px;
  }
}